import { useState, useEffect, useRef } from "react";

function Filters({ selectedFilters, setSelectedFilters, filters }) {
  // const [ selectedFilters, setSelectedFilters ] = useState(["Asda"]);
  const clickRef = useRef(null);
  const [numFiltersSelected, setNumFiltersSelected] = useState(0);
  const [sortedFilters, setSortedFilters] = useState([]);

  // set the number of selected filters
  useEffect(() => {
    const n = selectedFilters.length;
    setNumFiltersSelected(n);
  }, [selectedFilters]);

  function toggleFilterSelected(clickedFilter) {
    let newFilters;
    if (selectedFilters.includes(clickedFilter.filter)) {
      newFilters = selectedFilters.filter(
        (filter) => filter !== clickedFilter.filter
      );
    } else {
      newFilters = [...selectedFilters, clickedFilter.filter];
    }
    setSelectedFilters(newFilters);
  }

  function toggleFiltersShow() {
    document.getElementById("new-filters-button").style.display = "none";
    document.getElementById("new-filters-list").style.display = "block";
    document.getElementById("new-filters-list").scrollLeft = 0;
  }

  function toggleFiltersHide() {
    document.getElementById("new-filters-button").style.display = "block";
    document.getElementById("new-filters-list").style.display = "none";
  }

  // effect to detect clicks outside the filter list and hide filters again
  useEffect(() => {
    // Function to handle click event
    const handleClickOutside = (event) => {
      if (clickRef.current && !clickRef.current.contains(event.target)) {
        toggleFiltersHide();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const sorted = selectedFilters.slice();
    for (let i = 0; i < filters.length; i++) {
      if (!sorted.includes(filters[i])) {
        sorted.push(filters[i]);
      }
    }
    setSortedFilters(sorted);
  }, [selectedFilters]);

  return (
    <div ref={clickRef}>
      <div
        className="new-filters-container new-filters-list"
        id="new-filters-list"
      >
        <div className="new-filters-container-inner-div">
          {sortedFilters.map((filter, index) => (
            <button
              className={`new-filters-option ${
                selectedFilters.includes(filter) ? "selected" : ""
              }`}
              key={index}
              onClick={() => toggleFilterSelected({ filter })}
            >
              {filter}
            </button>
          ))}
        </div>
      </div>
      <div
        className="new-filters-container new-filters-button"
        id="new-filters-button"
      >
        <div className="new-filters-container-inner-div">
          <button
            className={`new-filters-option ${
              numFiltersSelected > 0 ? "selected" : ""
            }`}
            onClick={toggleFiltersShow}
          >
            {numFiltersSelected > 0 ? `${numFiltersSelected} ` : ""}Filters
          </button>
        </div>
      </div>
    </div>
  );
}

export default Filters;
