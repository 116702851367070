import { Offcanvas, Button } from "react-bootstrap";

import FeedbackButton from "../components/feedbackButton";
import StripeButton from "../components/stripeButton";
import menubutton from "../constants/icons/menu-button.png";

function OffcanvasMenu({
  showOffCanvas,
  setShowOffCanvas,
  handleFeedbackShow,
  title,
}) {
  const handleClose = () => setShowOffCanvas(false);
  const handleShow = () => setShowOffCanvas(true);

  return (
    <div>
      <Button className="off-canvas-button" onClick={handleShow}>
        <img
          src={menubutton}
          className="off-canvas-button-image"
          alt="menu-button"
        />
      </Button>
      <Offcanvas show={showOffCanvas} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="button-list">
          <FeedbackButton handleFeedbackShow={handleFeedbackShow} />
          <StripeButton />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
}

export default OffcanvasMenu;
