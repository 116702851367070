import { Button } from "react-bootstrap";
import { MdAdd } from "react-icons/md";

function AddFiltersButton({ setShowOffCanvas }) {
  const handleShow = () => setShowOffCanvas(true);

  return (
    <Button className="add-filters-button" onClick={handleShow}>
      <MdAdd /> Edit filters
    </Button>
  );
}

export default AddFiltersButton;
