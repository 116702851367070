import React, { useRef, useState, useEffect } from "react";

import { FaCopy } from "react-icons/fa";
import { FaDirections } from "react-icons/fa";

import {
  restaurantLogosMap,
  shopsLogosMap,
  fuelLogosMap,
  stationsLogoMap,
} from "../constants/logosMap";
import directionsClear from "../constants/icons/close-button.png";

function StationInfoBoxMobile({ station, setSelectedStation }) {
  const [isDragging, setIsDragging] = useState(false);
  const boxRef = useRef(null);
  const [height, setHeight] = useState(150); // initial height of div
  const [maxHeight, setMaxHeight] = useState(150);
  const startY = useRef(0);

  // copy address to clipboard
  const copyToClipboard = async () => {
    navigator.clipboard
      .writeText([station.latitude, station.longitude])
      .then(() => {
        // alert('Copied to clipboard!');
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  // open address in google maps
  const openMaps = async () => {
    const address = station.address.replaceAll(" ", "+");
    window.location.href = "https://www.google.co.uk/maps/dir//" + address;
    
  };

  // get logos
  const restaurantLogos = station.restaurants.map(
    (restaurant) =>
      restaurantLogosMap[restaurant] && (
        <img
          className="station-info-box-mobile-company-logo"
          src={restaurantLogosMap[restaurant]}
          alt={restaurant}
        />
      )
  );

  const shopLogos = station.shops.map(
    (shop) =>
      shopsLogosMap[shop] && (
        <img
          className="station-info-box-mobile-company-logo"
          src={shopsLogosMap[shop]}
          alt={shop}
        />
      )
  );

  const fuelLogos = station.fuel.map(
    (fuel) =>
      fuelLogosMap[fuel] && (
        <img
          className="station-info-box-mobile-company-logo"
          src={fuelLogosMap[fuel]}
          alt={fuel}
        />
      )
  );

  function getUniqueLogos(logos) {
    const foundLogos = [];
    for (let i = 0; i < logos.length; i++) {
      if (logos[i]) {
        if (foundLogos.includes(logos[i]["props"]["alt"])) {
          logos.splice(i, 1);
        } else {
          foundLogos.push(logos[i]["props"]["alt"]);
        }
      }
    }
    return logos;
  }

  var allLogos = restaurantLogos.concat(shopLogos).concat(fuelLogos);
  const uniqueLogos = getUniqueLogos(allLogos);

  useEffect(() => {
    function getBoxContentsHeight() {
      const parentElement = document.getElementById("station-info-box-mobile");
      const childElements = parentElement.children;
      let totalHeight = 0;
      for (let i = 0; i < childElements.length; i++) {
        const child = childElements[i];
        if ( child.className !== "station-info-box-mobile-close-button") {
          const rect = child.getBoundingClientRect();
          totalHeight += rect.height;
        }
      }
      return totalHeight;
    }
    const totalHeight = getBoxContentsHeight();

    if (totalHeight !== height && height === maxHeight) {
      setHeight(totalHeight);
    } else if (height === null) {
      setHeight(totalHeight);
    }
    setMaxHeight(totalHeight);
  }, [station]);

  // Handle making info box height draggable
  // Start dragging
  const onTouchStart = (e) => {
    setIsDragging(true);
    startY.current = window.innerHeight - e.touches[0].clientY;
  };

  // While dragging
  const onTouchMove = (e) => {
    if (isDragging) {
      const currentY = window.innerHeight - e.touches[0].clientY;
      const diff = currentY - startY.current;
      let newHeight;
      newHeight = height + diff;
      newHeight = Math.min(maxHeight, newHeight);
      setHeight(newHeight);
    }
  };

  // Stop dragging
  const onTouchEnd = (e) => {
    setIsDragging(false);
    if (height > maxHeight / 2) {
      setHeight(maxHeight);
    } else {
      setHeight(0);
    }
  };

  // Add event listeners for touch move and touch up
  useEffect(() => {
    if (isDragging) {
      document.addEventListener("touchmove", onTouchMove);
      document.addEventListener("touchend", onTouchEnd);
    } else {
      document.removeEventListener("touchmove", onTouchMove);
      document.removeEventListener("touchend", onTouchEnd);
    }

    return () => {
      document.removeEventListener("touchmove", onTouchMove);
      document.removeEventListener("touchend", onTouchEnd);
    };
  }, [isDragging]);

  // close station info box on button click
  const closeStationInfoBox = async () => {
    document.getElementById("station-info-box-mobile").style.display = "none";
    setSelectedStation(null);
  }

  // open station info box again if station changes
  useEffect(() => {
    if (station) {
      document.getElementById("station-info-box-mobile").style.display = "block";
    }
  }, [station])

  return (
    <div
      className="station-info-box-mobile"
      id="station-info-box-mobile"
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
      style={{
        height: `${height}px`,
        cursor: isDragging ? "grabbing" : "grab",
      }}
      ref={boxRef}
    >
      <div className="station-info-box-mobile-drag-icon-container">
        <div className="station-info-box-mobile-drag-icon"></div>
      </div>

      {station.provider && (
        <div className="station-info-box-mobile-logo-container">
          <img
            className="station-logo station-info-box-mobile-station-logo"
            src={stationsLogoMap[station.provider]}
            alt={station.provider}
          />
        </div>
      )}

      <b className="station-info-box-mobile-station-name-text">
        {station.name} Services
      </b>

      <br />
      <button
        className="marker-button station-info-box-mobile-marker-button"
        onClick={copyToClipboard}
      >
        Copy address <FaCopy />
      </button>
      <button
        className="marker-button station-info-box-mobile-marker-button"
        onClick={openMaps}
      >
        Open in maps <FaDirections />
      </button>

      <div className="station-info-box-mobile-company-logos-container">
        {uniqueLogos}
      </div>
      <button
        className="station-info-box-mobile-close-button"
        onClick={closeStationInfoBox}
      >
        <img
          src={directionsClear}
          className="station-info-box-mobile-close-button-img"
        >
        </img>
      </button>
    </div>
  );
}

export default StationInfoBoxMobile;
