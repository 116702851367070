import { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, GeoJSON, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import StationMarker from "./marker.js";
import LocationMarkerStart from "./locationMarkerStart.js";
import LocationMarkerEnd from "./locationMarkerEnd.js";
import CurrentLocationButton from "./currentLocationButton";
import {
  SubmitRoute,
  ConvertTextToCoordinates,
} from "../services/openRouteService.js";

function SetMapCenterAndZoom({ georoute, setCurrentBboxFilters }) {
  const map = useMap();

  // autozoom to plotted route
  useEffect(() => {
    if (georoute && georoute.bbox) {
      const bounds = L.latLngBounds([
        [georoute.bbox[1], georoute.bbox[0]], // Southwest corner
        [georoute.bbox[3], georoute.bbox[2]], // Northeast corner
      ]);
      const topPaddingLatDiff = (bounds.getNorth() - bounds.getSouth()) * 0.2;
      const bottomPaddingLatDiff =
        (bounds.getNorth() - bounds.getSouth()) * 0.05;

      const paddedBounds = L.latLngBounds([
        [bounds.getSouth() - bottomPaddingLatDiff, bounds.getWest()],
        [bounds.getNorth() + topPaddingLatDiff, bounds.getEast()],
      ]);

      const center = paddedBounds.getCenter();
      const zoom = map.getBoundsZoom(paddedBounds);

      map.flyTo(center, zoom);

      map.once("moveend", () => {
        setCurrentBboxFilters(map.getBounds());
        document.getElementById("search-area-button").style.display = "none";
      });
    }
  }, [map, georoute]);

  return null;
}

function BaseMap({
  position,
  zoom,
  stations,
  routeStart,
  routeEnd,
  setCurrentMapBbox,
  currentBboxFilters,
  setCurrentBboxFilters,
  hideSearchAreaButton,
  setHideSearchAreaButton,
  selectedFuelSubtype,
  defaultZoomLevel,
  selectedStation,
  setSelectedStation,
  setRouteBbox,
  endLatLon,
  setEndLatLon,
}) {
  const mapRef = useRef(null);
  const map = mapRef.current;

  const [route, setRoute] = useState(null);
  const [startLatLon, setStartLatLon] = useState([]);
  const [currentLatLon, setCurrentLatLon] = useState([]);

  // handles whether we should call the autocomplete api or not
  const [routesEnabled, setRoutesEnabled] = useState(true);
  const [routesEnabledTimer, setRoutesEnabledTimer] = useState(Date.now());

  let rectangles = [];

  function plotRouteBbox(routeBbox) {
    // clear previously plotted rectangles
    rectangles.forEach((rectangle) => {
      rectangle.remove();
    });
    rectangles = [];

    let upperLeftLL;
    let lowerRightLL;
    for (let i = 0; i < 10; i++) {
      upperLeftLL = [routeBbox[i]["lat"][0], routeBbox[i]["lon"][0]];
      lowerRightLL = [routeBbox[i]["lat"][1], routeBbox[i]["lon"][1]];
      const bounds = L.latLngBounds(upperLeftLL, lowerRightLL);
      const rectangle = L.rectangle(bounds, {
        color: "black",
        weight: 2,
        fillColor: "none",
        // fillOpacity: 0.4
      });
      // Add the rectangle to the map
      rectangle.addTo(map);
      rectangles.push(rectangle);
    }
  }

  function calculateRouteBbox(georoute) {
    const coordinates = georoute["features"][0]["geometry"]["coordinates"];
    // break down route into 10 segments and get segment bboxes
    const num_segments = 10;
    const segmentSizes = Math.floor(coordinates.length / num_segments);
    const segmentOverlap = Math.floor(segmentSizes / 5);
    let startId;
    let endId;
    let segmentStartLL;
    let segmentEndLL;
    let segmentWidth;
    let segmentHeight;
    let segmentCenter;
    const segmentPadding = 1;
    let segmentBbox;
    const routeBbox = [];

    for (let i = 0; i < num_segments; i++) {
      startId = Math.max(i * segmentSizes - segmentOverlap, 0);
      endId = Math.min(
        (i + 1) * segmentSizes + segmentOverlap,
        coordinates.length - 1
      );
      segmentStartLL = coordinates[startId];
      segmentEndLL = coordinates[endId];
      segmentWidth = Math.abs(segmentStartLL[0] - segmentEndLL[0]);
      segmentHeight = Math.abs(segmentStartLL[1] - segmentEndLL[1]);
      segmentCenter = [
        Math.min(segmentStartLL[0], segmentEndLL[0]) + segmentWidth / 2,
        Math.min(segmentStartLL[1], segmentEndLL[1]) + segmentHeight / 2,
      ];
      segmentWidth = segmentWidth * (1 + segmentPadding);
      segmentHeight = segmentHeight * (1 + segmentPadding);
      segmentBbox = {
        lon: [
          segmentCenter[0] - segmentWidth / 2,
          segmentCenter[0] + segmentWidth / 2,
        ],
        lat: [
          segmentCenter[1] - segmentHeight / 2,
          segmentCenter[1] + segmentHeight / 2,
        ],
      };
      routeBbox.push(segmentBbox);
    }
    setRouteBbox(routeBbox);
    // plotRouteBbox(routeBbox);
  }

  // get route for plotting
  useEffect(() => {
    const fetchRouteData = async () => {
      if (routeStart && routeEnd) {
        try {
          const georoute = await SubmitRoute(routeStart, routeEnd);
          setRoute(georoute);
          const bounds = L.latLngBounds([
            [georoute.bbox[1], georoute.bbox[0]], // Southwest corner
            [georoute.bbox[3], georoute.bbox[2]], // Northeast corner
          ]);
          calculateRouteBbox(georoute);
          // setRouteBbox(bounds);
          const startLL = georoute["features"][0]["geometry"]["coordinates"][0];
          setCurrentLatLon([startLL[1], startLL[0]]);
          const endLL =
            georoute["features"][0]["geometry"]["coordinates"].at(-1);
          setEndLatLon([endLL[1], endLL[0]]);
        } catch (error) {
          console.error("Error fetching route:", error);
          setRoute(null);
          setRouteBbox(null);
        }
      } else if (routeStart === null || routeEnd === null) {
        setRoute(null);
        setRouteBbox(null);
      }
    };

    fetchRouteData();
  }, [routeStart, routeEnd]);

  // get start lat and lon values
  useEffect(() => {
    const fetchStartLatLon = async () => {
      if (routeStart) {
        const startLL = await ConvertTextToCoordinates(routeStart);
        if (startLL === "disable") {
          console.log("disabling routes for 24 hrs...");
          setStartLatLon([]);
          setRoutesEnabled(false);
          setRoutesEnabledTimer(Date.now());
          disableRoutes();
          alert(
            "Route plotting is currently unavailable. Please try again later."
          );
        } else if (startLL) {
          setStartLatLon(startLL);
        } else {
          setStartLatLon([]);
          alert(
            "Error reading start address. Please ensure that it is " +
              "typed correcty and that is an address in the UK."
          );
        }
      }
    };
    fetchStartLatLon();
  }, [routeStart]);

  // get end lat and lon values
  useEffect(() => {
    const fetchEndLatLon = async () => {
      if (routeEnd) {
        const endLL = await ConvertTextToCoordinates(routeEnd);
        if (endLL === "disable") {
          console.log("disabling routes for 24 hrs...");
          setEndLatLon([]);
          setRoutesEnabled(false);
          setRoutesEnabledTimer(Date.now());
          disableRoutes();
          alert(
            "Route plotting is currently unavailable. Please try again later."
          );
        } else if (endLL) {
          setEndLatLon(endLL);
        } else {
          setEndLatLon([]);
          alert(
            "Error reading end address. Please ensure that it is " +
              "typed correcty and that is an address in the UK."
          );
        }
      }
    };
    fetchEndLatLon();
  }, [routeEnd]);

  // plot routeStart as marker on the map
  useEffect(() => {
    const plotRouteStartOnMap = async () => {
      if (startLatLon.length === 2 && routeStart !== "Current Location") {
        setCurrentLatLon([startLatLon[1], startLatLon[0]]);

        const center = [startLatLon[1], startLatLon[0]];
        const zoom = defaultZoomLevel;
        map.flyTo(center, zoom);
        map.once("moveend", () => {
          setCurrentBboxFilters(map.getBounds());
          if (hideSearchAreaButton === false) {
            setHideSearchAreaButton(true);
          }
        });
      }
    };
    plotRouteStartOnMap();
  }, [startLatLon]);

  // routesEnabled handler
  useEffect(() => {
    const checkInterval = 10000; // milliseconds
    const enableAfter = 86400000; // 24 hours

    const autofillEnabledHandler = async () => {
      if (!routesEnabled) {
        // if the interval time has passed, re-enable the routes function
        if (Date.now() - routesEnabledTimer > enableAfter) {
          console.log(
            "re-enabling routes after ",
            Date.now() - routesEnabledTimer,
            " seconds..."
          );
          setRoutesEnabled(true);
          enableRoutes();
        }
      }
    };
    // Set a timeout to delay the execution of the async function
    const timeoutId = setInterval(() => {
      autofillEnabledHandler();
    }, checkInterval);
    return () => {
      clearInterval(timeoutId);
    };
  }, [routesEnabled, setRoutesEnabled, routesEnabledTimer]);

  // disable routes
  function disableRoutes() {
    document.getElementById("search-box-start").style.display = "none";
    document.getElementById("search-box-end").style.display = "none";
    // document.getElementById('search-box').style.display = 'none';
    document.getElementById("current-location-button").style.display = "none";
    document.getElementById("swap-start-end-button").style.display = "none";
  }

  // enable routes
  function enableRoutes() {
    document.getElementById("search-box-start").style.display = "block";
    document.getElementById("search-box-end").style.display = "block";
    // document.getElementById('search-box').style.display = 'block';
    document.getElementById("current-location-button").style.display = "block";
    document.getElementById("swap-start-end-button").style.display = "block";
  }

  // update the current map information
  useEffect(() => {
    // If mapRef.current is not available, wait for it to load
    // if (!mapRef.current) return;
    // const map = mapRef.current;
    if (!map) return; // if map hasn't properly rendered yet

    const updateMapState = () => {
      setCurrentMapBbox(map.getBounds());
      if (hideSearchAreaButton === true) {
        setHideSearchAreaButton(false);
      }
    };
    // Add event listeners for zoom and moveend events
    map.on("zoomend", updateMapState);
    map.on("moveend", updateMapState);
    // Initial update
    updateMapState();
    // Cleanup the event listeners on component unmount
    return () => {
      map.off("zoomend", updateMapState);
      map.off("moveend", updateMapState);
    };
  }, [map, setCurrentMapBbox, setHideSearchAreaButton]);

  useEffect(() => {
    if (!map) return; // if map hasn't properly rendered yet

    // Handle when drag starts
    const handleDragStart = () => {
      if (hideSearchAreaButton !== true) {
        setHideSearchAreaButton(true); // Set to true when dragging starts
      }
    };
    // Handle when drag ends
    const handleDragEnd = () => {
      if (hideSearchAreaButton !== false) {
        setHideSearchAreaButton(false); // Set to false when dragging ends
      }
    };
    // handle clicks on pc
    const handleMouseDown = (event) => {
      if (event.originalEvent.target.className.includes("base-map")) {
        handleDragStart();
      }
    };
    const handleMouseUp = () => {
      handleDragEnd();
    };
    // handle touches on mobile
    const handleTouchStart = (event) => {
      if (event.target.className.includes("base-map")) {
        handleDragStart();
      }
    };
    const handleTouchEnd = () => {
      handleDragEnd();
    };
    // Add event listeners for both mouse and touch events
    map.on("mousedown", handleMouseDown);
    map.on("mouseup", handleMouseUp);
    map.getContainer().addEventListener("touchstart", handleTouchStart);
    map.getContainer().addEventListener("touchend", handleTouchEnd);

    // Clean up event listeners when the component unmounts
    return () => {
      map.off("mousedown", handleMouseDown);
      map.off("mouseup", handleMouseUp);
      map.removeEventListener("touchstart", handleTouchStart);
      map.removeEventListener("touchend", handleTouchEnd);
    };
  }, [map, hideSearchAreaButton, setHideSearchAreaButton]);

  return (
    <MapContainer
      className="base-map"
      center={position}
      zoom={zoom}
      scrollWheelZoom={true}
      zoomDelta={0.1}
      zoomControl={false}
      ref={mapRef}
    >
      <TileLayer
        // for previews, check HERE: https://leaflet-extras.github.io/leaflet-providers/preview/

        // attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        // url='https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png'
        url="https://tiles.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
      />
      <SetMapCenterAndZoom
        key={JSON.stringify(route) + "SetMapCenterAndZoom"}
        georoute={route}
        setCurrentBboxFilters={setCurrentBboxFilters}
      />
      {stations.length > 0 &&
        stations.map((station, index) => (
          <StationMarker
            id="station-marker"
            key={index}
            station={station}
            startLatLon={startLatLon}
            selectedFuelSubtype={selectedFuelSubtype}
            selectedStation={selectedStation}
            setSelectedStation={setSelectedStation}
          />
        ))}
      {route && (
        <GeoJSON key={JSON.stringify(route) + "GeoJSON"} data={route} />
      )}
      {/* {route && (
        <Marker 
          position={[startLatLon[1], startLatLon[0]]} 
          icon={L.divIcon({
            className: "route-start-marker",
            html: ReactDOMServer.renderToString(<FaDotCircle />),
          })}
        >
        </Marker>
      )} */}
      <CurrentLocationButton
        setCurrentLatLon={setCurrentLatLon}
        setCurrentBboxFilters={setCurrentBboxFilters}
        setHideSearchAreaButton={setHideSearchAreaButton}
        defaultZoomLevel={defaultZoomLevel}
      />
      <LocationMarkerStart latlon={currentLatLon} />
      <LocationMarkerEnd latlon={endLatLon} />
    </MapContainer>
  );
}

export default BaseMap;
