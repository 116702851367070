import { useEffect } from "react";

import { Marker } from "react-leaflet";
import L from "leaflet";

import locationmarker from "../constants/icons/location-marker.png";

function LocationMarkerEnd({ latlon }) {
  const locationIcon = new L.Icon({
    iconUrl: locationmarker,
    iconSize: [20, 20],
  });

  return (
    <div className="location-marker-end">
      {latlon.length === 2 && <Marker icon={locationIcon} position={latlon} />}
    </div>
  );
}

export default LocationMarkerEnd;
